<template>
  <div class="flex">
    <el-upload
      class="avatar-uploader"
      :action="url + '/api/admin.upload/upload'"
      :show-file-list="false"
      :auto-upload="false"
      v-loading="loading"
      :on-change="handleAvatarSuccess"
      :before-upload="beforeUpload"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div class="imgs flex" v-if="imgs.length > 0">
      <div
        v-for="(item, index) in imgs"
        class="ml-20 item"
        :key="index"
        :class="{ act: item.type !== 'image' && item.type !== 'video' }"
        :style="{
          width: item.type == 'image' || item.type == 'video' ? '' : 'auto',
        }"
        style="border: 1px solid #e5e5e5; width: 83px; height: 83px; border-radius: 6px"
      >
        <!-- <div style="width: 83px;height: 83px; border: 1px solid #E5E5E5;display: flex;"> -->
        <img :src="item.url" v-if="item.type == 'image'" />
        <video autopay :src="item.url" v-else-if="item.type == 'video'"></video>
        <!-- <span v-else>{{ item.url }}</span> -->
        <!-- <span>{{ item.url.split(".")[1] }}</span> -->
        <img v-if="getUrl(item) == 'xlsx'" src="@/assets/file/xlsx.png" />
        <img v-else-if="getUrl(item) == 'txt'" src="@/assets/file/txt.png" />
        <img v-else-if="getUrl(item) == 'doc'" src="@/assets/file/doc.png" />
        <img v-else-if="getUrl(item) == 'docx'" src="@/assets/file/docx.png" />
        <!-- <img v-else-if="getUrl(item) == 'png'" src="@/assets/file/png.png" /> -->
        <img v-else-if="getUrl(item) == 'zip'" src="@/assets/file/zip.png" />
        <img v-else-if="getUrl(item) == 'xls'" src="@/assets/file/xls.png" />
        <img v-else-if="getUrl(item) == 'rar'" src="@/assets/file/rar.png" />
        <img v-else-if="getUrl(item) == 'pdf'" src="@/assets/file/pdf.png" />
        <img v-else-if="getUrl(item) == 'dwg'" src="@/assets/file/dwg.png" />
        <!-- </div> -->
        <i class="el-icon-circle-close cur" @click="delImg(item, index)"></i>
      </div>
    </div>
    <!-- <div>
      <div v-for="(item, index) in imgs" class="ml-20" :key="index" :style="{
          display:
            item.type == 'image' || item.type == 'video' ? 'inline-block' : '',
        }">
       
        <div class="box">
          <img :src="item.url" v-if="item.type == 'image'" />
          <video :src="item.url" v-else-if="item.type == 'video'">-1"></video>

          <img v-if="getUrl(item) == 'xlsx' " src="@/assets/file/xlsx.png" />
          <img v-else-if="getUrl(item) == 'txt'" src="@/assets/file/txt.png" />
          <img v-else-if="getUrl(item) == 'doc'" src="@/assets/file/doc.png" />
          <img v-else-if="getUrl(item) == 'docx'" src="@/assets/file/docx.png" />
         
          <img v-else-if="getUrl(item) == 'zip'" src="@/assets/file/zip.png" />
          <img v-else-if="getUrl(item) == 'xls'" src="@/assets/file/xls.png" />
          <img v-else-if="getUrl(item) == 'rar'" src="@/assets/file/rar.png" />
          <img v-else-if="getUrl(item) == 'pdf'" src="@/assets/file/pdf.png" />
          <img v-else-if="getUrl(item) == 'dwg'" src="@/assets/file/dwg.png" />
        </div>
        <i class="el-icon-circle-close cur" @click="del(item, index)"></i>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: '',
      url: window.location.origin,
      imgs: [],
      loading: false,
    };
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    close(val) {
      if (val) {
        this.imgs = [];
      }
    },
    list(val) {
      if (this.imgs.length > 0 && this.imgs[0].url == '') this.imgs = [];
      if (val.length > 0 && this.imgs.length == 0) {
        this.imgs = [];
        val.forEach((item) => {
          let obj = {
            name: this.item.field,
            url: '',
          };
          if (item.indexOf('png') > -1 || item.indexOf('jpg') > -1 || item.indexOf('jpeg') > -1 || item.indexOf('gif') > -1) {
            obj.type = 'image';
            obj.url = item;
            this.imgs.push(obj);
          } else if (item.indexOf('mp4') > -1) {
            obj.type = 'video';
            obj.url = item;
            this.imgs.push(obj);
          } else {
            obj.url = item;
            this.imgs.push(obj);
          }
        });
      } else {
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.list.length > 0 && this.imgs.length == 0) {
        this.list.forEach((item) => {
          let obj = {
            name: this.item.field,
            url: '',
          };
          if (item.indexOf('png') > -1 || item.indexOf('jpg') > -1 || item.indexOf('jpeg') > -1 || item.indexOf('gif') > -1) {
            obj.type = 'image';
            obj.url = item;
            this.imgs.push(obj);
          } else if (item.indexOf('mp4') > -1) {
            obj.type = 'video';
            obj.url = item;
            this.imgs.push(obj);
          } else {
            obj.url = item;
            this.imgs.push(obj);
          }
        });
      }
    }, 1000);
  },
  methods: {
    delImg(item, index) {
      item.index = index;
      this.$emit('fileDel', item);
      this.imgs.splice(index, 1);
    },
    getUrl(item) {
      let List = item.url.split('.');
      let name = '';

      if (List.length > 0) {
        // let _url = `@/assets/file/${name}.png`;

        return List[List.length - 1];
      }
    },
    beforeUpload(file) {
      const isJPG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type);
      const isLtSize = file.size / 1024 < 500;
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }

      if (!isJPG) {
        this.$message.error('文件非 JPG/PNG/JPEG/GIF 格式');
        return false;
      }

      return true;
    },
    del(item, index) {
      this.imgs.splice(index, 1);
      item.index = index;
      this.$emit('fileDel', item);
    },
    handleAvatarSuccess(file) {
      let formData = new FormData();
      formData.append('file_name', file.raw);
      // formData.append("file", file.raw);
      this.loading = true;
      this.$http({
        url: '/api/admin.upload/upload',
        data: formData,
        header: true,
        done: (res) => {
          this.loading = false;
          if (file.raw.type.indexOf('image') > -1) {
            this.imgs.push({
              url: URL.createObjectURL(file.raw),
              name: this.item.field,
              type: 'image',
            });
          } else if (file.raw.type.indexOf('video') > -1) {
            this.imgs.push({
              url: URL.createObjectURL(file.raw),
              name: this.item.field,
              type: 'video',
            });
          } else {
            this.imgs.push({ url: file.name, name: this.item.field });
          }
          this.$emit('fileurl', {
            res: res,
            name: this.item.field,
            type: this.item.type2,
            title: file.raw.name,
          });
        },
      });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 50MB!');
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content {
  line-height: normal;
}

video {
  width: 88px;
  height: 84px;
}

.imgs {
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto; /* 修改为 overflow-y: auto 以实现 y 轴滚动 */
  overflow-x: hidden; /* 确保 x 轴没有滚动 */
}
.item {
  margin: 0 10px 10px 10px;
}

.imgs div {
  position: relative;

  span {
    display: inline-block;
    width: 160px;
    height: 33px;
    overflow: hidden;
  }

  img {
    width: 88px;
    height: 84px;
  }

  i {
    position: absolute;
    right: -18px;
    color: #409eff;
    font-size: 18px;
    top: 2px;
  }
}
</style>
<style>
.avatar {
  width: 80px;
  height: 80px;
}

.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #e5e5e5;
  width: 83px;
  height: 83px;
  line-height: 83px;
  font-size: 40px;
  text-align: center;
}

.imgs div {
  position: relative;

  img {
    width: 86px;
    height: 86px;
  }

  i {
    position: absolute;
    right: -18px;
    color: #409eff;
    font-size: 18px;
  }
}
</style>