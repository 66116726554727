export default [
  // 劳务公司
  {
    path: "/labor/index",
    name: "laborIndex",
    component: () => import("@/views/labor/index"),
  },
  {
    path: "/labor/index2",
    name: "laborIndex2",
    component: () => import("@/views/labor/index2"),
  },
  {
    path: "/labor/company",
    name: "laborCompany",
    component: () => import("@/views/labor/company"),
  },
  {
    path: "/labor/person",
    name: "laborPersonMag",
    component: () => import("@/views/labor/personMag/person"),
  },
  {
    path: "/labor/person2",
    name: "laborPersonMag",
    component: () => import("@/views/labor/personMag/person2"),
  },
  {
    path: "/labor/personNeed",
    name: "personMagNeed",
    component: () => import("@/views/labor/personMag/need"),
  },
  {
    path: "/labor/personSupply",
    name: "personMagSupply",
    component: () => import("@/views/labor/personMag/supply"),
  },
  {
    path: "/labor/personSupply2",
    name: "personMagSupply",
    component: () => import("@/views/labor/personMag/supply2"),
  },
  {
    path: "/labor/platformDetails",
    name: "platformDetails",
    component: () => import("@/views/labor/projectMag/platformDetails"),
  },
  {
    path: "/labor/projectMag",
    name: "laborProjectMag",
    component: () => import("@/views/labor/projectMag"),
  },
  {
    path: "/labor/projectMagzu",
    name: "laborProjectMagzu",
    component: () => import("@/views/labor/projectMagzu"),
  },
];
