<template>
  <div class="addkeysinput">
    <div class="mains">
      <div>
        <el-tag v-for="tag in tags" :key="tag.name" closable @close="handleClose(tag)">
          {{ tag.name }}
        </el-tag>
      </div>
      <div><el-input v-model="input" placeholder="请输入内容" @change="addTag"></el-input></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: '',
      tags: [],
    };
  },
  computed: {
    myList() {
      return this.list;
    },
  },
  watch: {
    list(val) {
      if (val) {
        this.tags = val.map((el) => {
          return { name: el };
        });
      }else{
        this.tags = []
      }
    },
  },
  methods: {
    handleClose(e) {
      this.tags = this.tags.filter((el, index) => {
        return el.name !== e.name;
      });
      this.$emit('deKeys', this.tags);
    },
    addTag(el) {
      this.tags.push({
        name: el,
      });
      this.$emit('getinputKeys', this.tags);
      this.input = '';
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.addkeysinput {
  width: 100%;
  /deep/ .mains {
    display: flex;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    input {
      border: none;
    }
  }
}
</style>
