export default [
  // 劳务公司
  {
    path: "/consult/brife",
    name: "consultbrife",
    component: () => import("@/views/consult/brife"),
  },
  {
    path: "/consult/index",
    name: "consultIndex",
    component: () => import("@/views/consult/index"),
  },
  {
    path: "/consult/index2",
    name: "consultIndex2",
    component: () => import("@/views/consult/index2"),
  },
  {
    path: "/consult/list",
    name: "consultList",
    component: () => import("@/views/consult/list"),
  },
  {
    path: "/consult/records",
    name: "consultRecords",
    component: () => import("@/views/consult/records"),
  },
  {
    path: "/consult/visa",
    name: "consultVisa",
    component: () => import("@/views/consult/visa"),
  },
  {
    path: "/consult/clear",
    name: "consultClear",
    component: () => import("@/views/consult/clear"),
  },
  {
    path: "/consult/end",
    name: "consultEnd",
    component: () => import("@/views/consult/end"),
  },
  {
    path: "/consult/process",
    name: "consultEnd",
    component: () => import("@/views/consult/process"),
  },
  {
    path: "/consult/change",
    name: "consultEnd",
    component: () => import("@/views/consult/change"),
  },
  {
    path: "/consult/dictionary",
    name: "consultDictionary",
    component: () => import("@/views/consult/dictionary"),
  },
  {
    path: "/consult/waitCase",
    name: "consultWaitCase",
    component: () => import("@/views/consult/waitCase"),
  },
  {
    path: "/consult/waitCaseplan",
    name: "consultWaitCaseplan",
    component: () => import("@/views/consult/waitCase"),
  },
  {
    path: "/consult/doc",
    name: "consultDoc",
    component: () => import("@/views/consult/doc"),
  },
];
