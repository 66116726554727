export default [
  // 概况
  {
    path: "/project/brife",
    name: "brife",
    component: () => import("@/views/project/brife"),
  },
  {
    path: "/project/plan",
    name: "plan",
    component: () => import("@/views/project/plan"),
  },
  {
    // 安全日志
    path: "/project/dictionary/safe",
    name: "dictionarySafe",
    component: () => import("@/views/project/dictionary/safe/index"),
  },
  {
    // 施工日志
    path: "/project/dictionary/work",
    name: "dictionaryWork",
    component: () => import("@/views/project/dictionary/work/index"),
  },
  {
    //待办
    path: "/project/waitCase/wait",
    name: "waitCasewait",
    component: () => import("@/views/project/waitCase/index"),
  },
  {
    // 计划
    path: "/project/waitCase/plan",
    name: "waitCaseplan",
    component: () => import("@/views/project/waitCase/plan"),
  },
  {
    path: "/project/procedure",
    name: "procedure",
    component: () => import("@/views/project/proceed/procedure"),
  },
  {
    path: "/project/doc",
    name: "projectDoc",
    component: () => import("@/views/project/proceed/doc"),
  },
  // 人造机
  // 人员
  {
    path: "/project/material/person",
    name: "materialperson",
    component: () => import("@/views/project/material/person"),
  },
  {
    path: "/project/material/personDetail",
    name: "materialPersonDetail",
    component: () => import("@/views/project/material/details/person"),
  },
  // 材料
  {
    path: "/project/material/stuff",
    name: "materialStuff",
    component: () => import("@/views/project/material/stuff"),
  },
  {
    path: "/project/material/stuffDetail",
    name: "materialStuffDetail",
    component: () => import("@/views/project/material/details/stuff"),
  },
  {
    path: "/project/material/machine",
    name: "materialMmchine",
    component: () => import("@/views/project/material/machine"),
  },
  {
    path: "/project/material/machineDetail",
    name: "materialMachineDetail",
    component: () => import("@/views/project/material/details/machine"),
  },
  // 机械
  {
    path: "/project/material/person",
    name: "materialPerson",
    component: () => import("@/views/project/material/machine"),
  },
  // 信息管理
  {
    path: "/project/infoMag/need",
    name: "infoMagNeed",
    component: () => import("@/views/project/infoMag/need"),
  },
  {
    path: "/project/infoMag/buy",
    name: "infoMagBuy",
    component: () => import("@/views/project/infoMag/buy"),
  },
  {
    path: "/project/infoMag/other",
    name: "infoMagOther",
    component: () => import("@/views/project/infoMag/other"),
  },
  // 工程造价
  {
    path: "/project/cost/clear",
    name: "costClear",
    component: () => import("@/views/project/cost/clear"),
  },
  {
    path: "/project/cost/pleaseMoney",
    name: "costPleaseMoney",
    component: () => import("@/views/project/cost/pleaseMoney"),
  },
  {
    path: "/project/cost/count",
    name: "costCount",
    component: () => import("@/views/project/cost/count"),
  },
  {
    path: "/project/cost/visa",
    name: "costVisa",
    component: () => import("@/views/project/cost/visa"),
  },
  // 工程成本
  {
    path: "/project/prime/rcj/person",
    name: "rcjPerson",
    component: () => import("@/views/project/prime/rcj/person"),
  },
  {
    path: "/project/prime/rcj/machine",
    name: "rcjMachine",
    component: () => import("@/views/project/prime/rcj/machine"),
  },
  {
    path: "/project/prime/rcj/fund",
    name: "rcjFund",
    component: () => import("@/views/project/prime/rcj/fund"),
  },
  {
    path: "/project/prime/visa",
    name: "primeVisa",
    component: () => import("@/views/project/prime/visa"),
  },
  {
    path: "/project/prime/amount",
    name: "primeAmount",
    component: () => import("@/views/project/prime/amount"),
  },
  {
    path: "/project/prime/other",
    name: "primeOther",
    component: () => import("@/views/project/prime/other"),
  },
  {
    path: "/project/prime/costSummary",
    name: "primeSummary",
    component: () => import("@/views/project/prime/costSummary"),
  },
  // 预警
  {
    path: "/project/warn/process",
    name: "warnProcess",
    component: () => import("@/views/project/warn/process"),
  },
  {
    path: "/project/warn/cost",
    name: "warnCost",
    component: () => import("@/views/project/warn/cost"),
  },
  {
    path: "/project/warn/person",
    name: "warnPerson",
    component: () => import("@/views/project/warn/person"),
  },
  {
    path: "/project/warn/material",
    name: "warnMaterial",
    component: () => import("@/views/project/warn/material"),
  },
  {
    path: "/project/warn/machine",
    name: "warnMachine",
    component: () => import("@/views/project/warn/machine"),
  },
  {
    path: "/project/warn/costWarning",
    name: "costWarning",
    component: () => import("@/views/project/warn/costWarning"),
  },
  {
    path: "/project/contract/material",
    name: "contractMaterial",
    component: () => import("@/views/project/contract/material"),
  },
  {
    path: "/project/contract/machine",
    name: "contractMaterial",
    component: () => import("@/views/project/contract/machine"),
  },
  {
    path: "/project/contract/fenbao",
    name: "contractMaterial",
    component: () => import("@/views/project/contract/fenbao"),
  },
  {
    path: "/project/contract/depart",
    name: "contractMaterial",
    component: () => import("@/views/project/contract/depart"),
  },
  {
    path: "/project/contract/other",
    name: "contractMaterial",
    component: () => import("@/views/project/contract/other"),
  },
  {
    path: "/common/platChange",
    name: "platChange",
    component: () => import("@/views/common/platChange"),
  },
];
