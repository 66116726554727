<template>
  <!-- <el-cascader
              class="mr-20"
              :options="options"
              clearable
              :key="key"
              v-model="addr"
              :props="cascaderProps"
              @change="handleChange"></el-cascader> -->
  <!-- <el-cascader
                :options="options"
                placeholder="请选择地区"
                expand-trigger="hover"
                ref="cascaderAddr"
                clearable
                v-model="selectedOptions"
                @change="handleChange">
            </el-cascader> -->
  <el-cascader :options="options" filterable clearable placeholder="请选择地址" ref="cascaderAddr" :props="cascaderProps" v-model="selectedOptions" :disabled="isDisabled" @change="handleChange">
  </el-cascader>
</template>
<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
import { provs_data, citys_data, dists_data } from '../../static/city.js';
export default {
  data() {
    return {
      cascaderProps: {
        label: 'area_name', //当返回的数据字段不是label和value可以改成实际的字段比如goodsName
        value: 'id',
      },
      options: [],
      selectedOptions: [],
      key: 0,
      name: [],
      addr: [],
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list(val) {
      this.selectedOptions = val.map(el=>{
        return Number(el)
      });
    },
    isDisabled(val) {},
  },
  mounted() {
    // this.options = provs_data
    setTimeout(() => {
      this.selectedOptions =this.list;
    }, 400);
    this.$http({
      url: '/api/index.Condition/list',
      data: {
        key: ['area'],
      },
      done: (res) => {
        this.options = res.area
      },
    });
  },
  methods: {
    lazyLoad(node, resolve) {
      this.names = node.pathLabels;
      setTimeout(() => {
        if (node.level != 3) {
          if (dists_data[node.value]) {
            dists_data[node.value].forEach((element) => {
              element.children = [];
            });
          }
          const nodes = citys_data[node.value] || dists_data[node.value];

          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes);
        } else {
          resolve([]);
        }
      }, 200);
      // this.getData(node, resolve);
    },
    handleChange(e) {
      console.log(e,'eeeeeeeeeeeeeeeeeeeeeeeee');
      // console.log(this.names.join(' '))
      if (e.length > 0) {
        let names = CodeToText[e[0]] + ' ' + CodeToText[e[1]] + ' ' + CodeToText[e[2]];
        this.$emit('getadd', {
          name: this.item.field || this.item.prop,
          data: names,
          addr: e,
        });
      } else {
        this.$emit('getadd', {
          name: this.item.field || this.item.prop,
          data: [],
          addr: [],
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-cascader {
  width: 100%;
}
</style>
