import Vue from "vue";
import Vuex from "vuex";
// import tagsView from './modules/tagsView'
// import common from './modules/common'
import consult from "./modules/consult";
Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		isAlertShown: false, // 标志位，默认为false，表示提示框还没有被弹出,
		endDay:'',
		searchObj: null,
	},
	modules: {
		consult,

	},
	mutations: {
	    showAlert(state) {
	      state.isAlertShown = true
	    },
	    hideAlert(state) {
	      state.isAlertShown = false
	    },
		setSesrchContent(state, data) {
			state.searchObj = data
		}
	  },
});
