<template>
  <el-dialog title="平台客服" modal-append-to-body :visible="visible" width="500px" center :before-close="close">
    <el-descriptions column="1">
      <el-descriptions-item label="联系方式">15102834314</el-descriptions-item>
      <el-descriptions-item label="企业微信"><el-image style="width: 300px; height: 300px" :src="require('@/assets/WeCom.jpg')" fit="contain" /></el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
