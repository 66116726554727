export default [
  {
    path: "/front/about",
    name: "frontAbout",
    component: () => import("@/views/front/about"),
  },
  {
    path: "/",
    component: () => import("@/views/front/intro"),
    name: "frontintro",
  },
  // {
  //     path: '/',
  //     name: 'frontIndex',
  //     component: () =>
  //         import ('@/views/front/index')
  // },
  {
    path: "/front/hire",
    name: "frontHire",
    // component: () => import("@/views/front/hire"),
    component: () => import("@/views/front/newPage/index"),
  },
  {
    path: "/front/hire/search",
    name: "frontHireSearch",
    component: () => import("@/views/front/newPage/searchIndex"),
  },
  {
    path: "/front/hire/info",
    name: "frontHireRecruitInfo",
    component: () => import("@/views/front/newPage/recruit/recruitInfo"),
  },
  {
    path: "/front/fee",
    component: () => import("@/views/front/fee"),
    name: "frontfee",
  },
  //   {
  //     path: "/front/intro",
  //     component: () => import("@/views/front/intro"),
  //     name: "frontintro",
  //   },
  {
    path: "/front/business",
    name: "frontbusiness",
    // component: () => import("@/views/front/business"),
    component: () => import("@/views/front/newPage/index"),
  },
  {
    path: "/front/business/search",
    name: "frontBusinessSearch",
    component: () => import("@/views/front/newPage/searchIndex"),
  },
  {
    path: "/front/leaveMsg",
    component: () => import("@/views/front/leaveMsg"),
    name: "leaveMsg",
  },
  {
    path: "/front/machine",
    name: "frontmachine",
    // component: () => import("@/views/front/machine"),
    component: () => import("@/views/front/newPage/index"),
  },
  {
    path: "/front/machine/search",
    name: "frontMachineSearch",
    component: () => import("@/views/front/newPage/searchIndex"),
  },
  {
    path: "/front/material",
    name: "frontmaterial",
    // component: () => import("@/views/front/material"),
    component: () => import("@/views/front/newPage/index"),
  },
  {
    path: "/front/material/search",
    name: "frontMaterialSearch",
    component: () => import("@/views/front/newPage/searchIndex"),
  },
  {
    path: "/front/service",
    component:() =>import("@/views/front/service"),
    name: "frontservice",
  },
  {
    path: "/front/services/tech",
    component: () => import("@/views/front/services/tech"),
    name: "frontservice",
  },
  {
    path: "/front/services/cost",
    component: () => import("@/views/front/services/cost"),
    name: "frontcost",
  },
  {
    path: "/front/services/law",
    component: () => import("@/views/front/services/law"),
    name: "frontlaw",
  },
  {
    path: "/front/services/tax",
    component: () => import("@/views/front/services/tax"),
    name: "fronttax",
  },
  {
    path: "/front/services/plat",
    component: () => import("@/views/front/services/plat"),
    name: "frontplat",
  },
  {
    path: "/front/orderDetails/recHire",
    component: () => import("@/views/front/orderDetails/recHire"),
    name: "frontrecHire",
  },
  {
    path: "/front/orderDetails/hunHire",
    component: () => import("@/views/front/orderDetails/hunHire"),
    name: "fronthunHire",
  },
  {
    path: "/front/orderDetails/laborHire",
    component: () => import("@/views/front/orderDetails/laborHire"),
    name: "frontlaborHire",
  },
  {
    path: "/front/orderDetails/supHire",
    component: () => import("@/views/front/orderDetails/supHire"),
    name: "frontsupHire",
  },
  {
    path: "/front/orderDetails/supMaterial",
    component: () => import("@/views/front/orderDetails/supMaterial"),
    name: "frontsupMaterial",
  },
  {
    path: "/front/orderDetails/materialListDetails",
    component: () => import("@/views/front/orderDetails/materialListDetails"),
    name: "frontmaterialListDetails",
  },
  {
    path: "/front/orderDetails/purMaterial",
    component: () => import("@/views/front/orderDetails/purMaterial"),
    name: "frontpurMaterial",
  },

  {
    path: "/front/orderDetails/supMachine",
    component: () => import("@/views/front/orderDetails/supMachine"),
    name: "frontsupsupMachine",
  },
  {
    path: "/front/orderDetails/purMachine",
    component: () => import("@/views/front/orderDetails/purMachine"),
    name: "frontpupurMachine",
  },

  {
    path: "/front/services/doc",
    component: () => import("@/views/front/details/doc"),
    name: "frontdoc",
  },
  {
    path: "/front/services/detail",
    component: () => import("@/views/front/details/service"),
    name: "frontdoc",
  },
  {
    path: "/front/services/detail2",
    component: () => import("@/views/front/details/service2"),
    name: "frontdoc",
  },
  {
    path: "/front/frontfee2",
    component: () => import("@/views/front/frontfee2"),
    name: "frontfee2",
  },
  //招聘详情
  {
    path: "/front/newPage/info/newrecHire",
    component: () => import("@/views/front/newPage/info/newrecHire"),
    name: "frontNewtrecHire",
  },
  // 求职详情
  {
    path: "/front/newPage/info/newhunHire",
    component: () => import("@/views/front/newPage/info/newhunHire"),
    name: "frontNewhunHire",
  },
  //劳务详情
  {
    path: "/front/info/newlabor",
    component: () => import("@/views/front/newPage/info/newlabor"),
    name: "frontNewlabor",
  },
  //供应详情
  {
    path: "/front/newPage/info/newsupplyDetails",
    component: () => import("@/views/front/newPage/info/newsupplyDetails"),
    name: "frontSupplyDetails",
  },
  //业务大厅公司详情
  {
    path: "/front/newPage/info/newconDetail",
    component: () => import("@/views/front/newPage/info/newconDetail"),
    name: "frontNewconDetail",
  },
  //业务大厅专家详情
  {
    path: "/front/newPage/info/newperDetal",
    component: () => import("@/views/front/newPage/info/newperDetal"),
    name: "frontNewperDetal",
  },
  //报价详情
  {
    path: "/front/quotationDetails",
    component: () => import("@/views/front/newPage/info/quotationDetails"),
    name: "frontQuotationDetails",
  },
  //材料/机械店铺详情
  {
    path: "/front/storeDetails",
    component: () => import("@/views/front/newPage/info/storeDetails"),
    name: "frontStoreDetails",
  },
  //操作手册
  {
    path: "/front/newPage/info/operate",
    component: () => import("@/views/front/newPage/info/operate"),
    name: "frontOperate",
  },
  //线下培训
  {
    path: "/front/newPage/info/train",
    component: () => import("@/views/front/newPage/info/train"),
    name: "frontTrain",
  },
  //手册、培训详情
  {
    path: "/front/newPage/info/newdoc",
    component: () => import("@/views/front/newPage/info/newdoc"),
    name: "frontNewdoc",
  },
];
