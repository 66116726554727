export default [
  // 施工企业
  {
    path: "/doer/index",
    name: "doerindex",
    component: () => import("@/views/doer/index"),
  },
  {
    path: "/doer/project",
    name: "doerproject",
    component: () => import("@/views/doer/project"),
  },
  {
    path: "/doer/supplier/stuff",
    name: "supplierStuff",
    component: () => import("@/views/doer/supplier/stuff"),
  },
  {
    path: "/doer/supplier/machine",
    name: "supplierMachine",
    component: () => import("@/views/doer/supplier/machine"),
  },
  {
    path: "/doer/supplier/labor",
    name: "supplierLabor",
    component: () => import("@/views/doer/supplier/labor"),
  },
  // 需求
  {
    path: "/doer/need/hire",
    name: "needHire",
    component: () => import("@/views/doer/need/hire"),
  },
  {
    path: "/doer/need/labor",
    name: "needLabor",
    component: () => import("@/views/doer/need/labor"),
  },
  {
    path: "/doer/need/stuff",
    name: "needStuff",
    component: () => import("@/views/doer/need/stuff"),
  },
  {
    path: "/doer/need/machine",
    name: "needStuff",
    component: () => import("@/views/doer/need/machine"),
  },
];
