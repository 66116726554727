export const project = [
  { name: "项目概况", path: "/project/brife", icon: "gaikuang" },
  { name: "项目筹划", path: "/project/plan", icon: "chouhua" },

  {
    name: "日志管理",
    path: "/project/dictionary",
    icon: "rizhi",
    children: [
      { name: "安全日志", path: "/project/dictionary/safe" },
      { name: "施工日志", path: "/project/dictionary/work" },
    ],
  },
  {
    name: "待办事项",
    path: "/project/twaitCase",
    icon: "shixiang",
    children: [
      { name: "待办事项列表", path: "/project/waitCase/wait" },
      { name: "计划事项列表", path: "/project/waitCase/plan" },
    ],
  },
  {
    name: "工程进度",
    path: "/project/procedure",
    icon: "xiangmu",
    children: [
      { name: "工程进度", path: "/project/procedure" },
      { name: "工程资料", path: "/project/doc" },
    ],
  },
  {
    name: "人材机",
    path: "/project/material",
    icon: "rencaiji",
    children: [
      { name: "人员", path: "/project/material/person" },
      { name: "材料", path: "/project/material/stuff" },
      { name: "机械", path: "/project/material/machine" },
    ],
  },
  {
    name: "工程造价",
    path: "/project/cost",
    icon: "zaojia",
    children: [
      { name: "清标对比", path: "/project/cost/clear" },
      { name: "请款金额", path: "/project/cost/pleaseMoney" },
      { name: "结算金额", path: "/project/cost/count" },
      { name: "签证及变更", path: "/project/cost/visa" },
    ],
  },
  {
    name: "工程成本",
    path: "/project/prime",
    icon: "chengben",
    children: [
      {
        name: "工程量成本",
        children: [
          { name: "工程量成本", path: "/project/prime/amount" },
          { name: "签证及变更", path: "/project/prime/visa" },
        ],
      },
      {
        name: "人材机成本",
        path: "/project/prime/rcj",
        children: [
          { name: "人员", path: "/project/prime/rcj/person" },
          { name: "材料", path: "/project/prime/rcj/fund" },
          { name: "机械", path: "/project/prime/rcj/machine" },
        ],
      },
      { name: "其他成本", path: "/project/prime/other" },
    ],
  },
  {
    name: "工程预警",
    path: "/project/warn",
    icon: "yujing",
    children: [
      { name: "进度预警", path: "/project/warn/process" },
      { name: "造价预警", path: "/project/warn/cost" },
      { name: "人员预警", path: "/project/warn/person" },
      { name: "材料预警", path: "/project/warn/material" },
      { name: "机械预警", path: "/project/warn/machine" },
    ],
  },
  {
    name: "合同管理",
    path: "/project/contract",
    icon: "hetong",
    children: [
      { name: "材料合同", path: "/project/contract/material" },
      { name: "机械合同", path: "/project/contract/machine" },
      { name: "劳务合同", path: "/project/contract/depart" },
      { name: "分包合同", path: "/project/contract/fenbao" },
      { name: "其他合同", path: "/project/contract/other" },
    ],
  },
  {
    name: "信息管理",
    path: "/project/infoMag",
    icon: "xinxi",
    children: [
      { name: "人才招聘", path: "/common/hire" },
      { name: "工程劳务需求", path: "/project/infoMag/need" },
      { name: "采购发布", path: "/project/infoMag/buy" },
      { name: "平台推送", path: "/common/push" },
      { name: "其他服务", path: "/project/infoMag/other" },
    ],
  },
  { name: "我的顾问", path: "/common/adviser", icon: "guwen" },
];
export const project2 = [
  { name: "项目概况", path: "/project/brife", icon: "gaikuang", children: [] },

  {
    name: "日志管理",
    path: "/project/dictionary",
    icon: "rizhi",
    children: [
      { name: "安全日志", path: "/project/dictionary/safe", children: [] },
      { name: "施工日志", path: "/project/dictionary/work", children: [] },
    ],
  },
  {
    name: "待办事项",
    path: "/project/twaitCase",
    icon: "shixiang",
    children: [
      { name: "待办事项列表", path: "/project/waitCase/wait", children: [] },
      { name: "计划事项列表", path: "/project/waitCase/plan", children: [] },
    ],
  },
  {
    name: "工程进度",
    path: "/project/procedure",
    icon: "xiangmu",
    children: [
      { name: "工程进度", path: "/project/procedure", children: [] },
      { name: "工程资料", path: "/project/doc", children: [] },
    ],
  },
];
export const allMag = [
  {
    name: "控制面板",
    path: "/all/index",
    icon: "mianban",
  },
  {
    name: "我的信息",
    path: "/all/info",
    icon: "biangeng",
    children: [
      { name: "我的资料", path: "/all/me/info" },
      { name: "账号管理", path: "/all/me/mag" },
      { name: "我的账户", path: "/common/company/account" },
    ],
  },
  {
    name: "项目列表",
    icon: "gaikuang",
    path: "/all/list",
  },
  {
    name: "供应商",
    path: "/all/supplier",
    icon: "gongyingshang",
    children: [
      { name: "材料供应商", path: "/all/supplier/stuff?id=1" },
      { name: "机械供应商", path: "/all/supplier/machine?id=2" },
      { name: "劳务供应商", path: "/all/supplier/labor?id=3" },
    ],
  },
];
// 班组长账号
export const banzuzhang = [
  {
    name: "控制面板",
    path: "/labor/index2",
    icon: "mianban",
    children: [],
  },
  {
    name: "我的信息",
    path: "/all/info",
    icon: "biangeng",
    children: [{ name: "我的资料", path: "/all/me/info", children: [] }],
  },
  {
    name: "人员供需管理",
    path: "/labor/personMag",
    children: [
      { name: "人员管理", path: "/labor/person", children: [] },
      { name: "供应事项管理", path: "/labor/personSupply2", children: [] },
      { name: "需求事项管理", path: "/common/myadvice2", children: [] },
      { name: "人才招聘", path: "/common/hire2", children: [] },
    ],
  },
  {
    name: "项目管理",
    path: "/labor/projectMagzu",
    children: [],
    // children: [
    //     { name: '平台项目的明细', path: '/labor/platformDetails'},
    //     { name: '自建项目的明细', path: '/labor/selfDetails' }
    // ]
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
    children: [],
  },
  {
    name: "平台推送",
    path: "/common/push",
    children: [
      { name: "交易推送", path: "/common/platformPush", children: [] },
      { name: "其他推送", path: "/common/push", children: [] },
    ],
  },
];

export const doer = [
  {
    name: "控制面板",
    path: "/doer/index",
    imga: "/doer-icon/nav-1-a.png",
    imgg: "/doer-icon/nav-1-g.png",
  },
  {
    name: "企业信息",
    path: "/common/company",
    icon: "biangeng",
    imga: "/doer-icon/nav-2-a.png",
    imgg: "/doer-icon/nav-2-g.png",
    children: [
      { name: "企业资料", path: "/common/company/doc" },
      { name: "我的账户", path: "/common/company/account" },
      { name: "账号管理", path: "/common/company/mag" },
      { name: "企业业绩", path: "/common/company/achievment" },
    ],
  },
  {
    name: "工程项目",
    path: "/doer/project",
    imga: "/doer-icon/nav-3-a.png",
    imgg: "/doer-icon/nav-3-g.png",
  },
  {
    name: "供应商管理",
    path: "/supplier",
    imga: "/doer-icon/nav-4-a.png",
    imgg: "/doer-icon/nav-4-g.png",
    children: [
      { name: "材料供应商", path: "/doer/supplier/stuff" },
      { name: "机械供应商", path: "/doer/supplier/machine" },
      { name: "劳务供应商", path: "/doer/supplier/labor" },
    ],
  },
  {
    name: "需求管理",
    path: "/need",
    imga: "/doer-icon/nav-5-a.png",
    imgg: "/doer-icon/nav-5-g.png",
    children: [
      { name: "人才招聘", path: "/common/hire" },
      { name: "工程劳务需求", path: "/project/infoMag/need" },
      { name: "材料", path: "/doer/need/stuff" },
      { name: "机械", path: "/doer/need/machine" },
    ],
  },
  {
    name: "咨询服务",
    path: "/common/myadviceD",
    imga: "/doer-icon/nav-6-a.png",
    imgg: "/doer-icon/nav-6-g.png",
    children: [
      { name: "咨询服务", path: "/common/service" },
      { name: "我的咨询", path: "/common/myadvice" },
    ],
  },
  {
    name: "平台推送",
    path: "/common/push",
    imga: "/doer-icon/nav-7-a.png",
    imgg: "/doer-icon/nav-7-g.png",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
// 供应商
export const supplier = [
  {
    name: "控制面板",
    path: "/supplier/index",
    imga: "/doer-icon/nav-1-a.png",
    icon: "mianban",
    imgg: "/doer-icon/nav-1-g.png",
  },
  {
    name: "企业信息",
    path: "/common/company",
    icon: "biangeng",
    imga: "/doer-icon/nav-2-a.png",
    imgg: "/doer-icon/nav-2-g.png",
    children: [
      { name: "企业资料", path: "/common/company/doc" },
      { name: "我的账户", path: "/common/company/account" },
      { name: "账号管理", path: "/common/company/mag" },
    ],
  },
  {
    name: "产品管理",
    path: "/supplier/product",
    imga: "/doer-icon/nav-4-a.png",
    imgg: "/doer-icon/nav-4-g.png",
    children: [
      { name: "供应产品管理", path: "/supplier/productSupply" },
      { name: "需求产品管理", path: "/supplier/productNeed" },
    ],
  },
  {
    name: "工程项目",
    path: "/supplier/project",
    imga: "/doer-icon/nav-6-a.png",
    imgg: "/doer-icon/nav-6-g.png",
    // children: [
    //     { name: '材料供应商', path: '/supplier/material'},
    //     { name: '机械供应商', path: '/supplier/machine' },
    // ]
  },
  {
    name: "需求服务",
    path: "/common/myadviceF",
    imga: "/doer-icon/nav-5-a.png",
    imgg: "/doer-icon/nav-5-g.png",
    children: [
      { name: "人才招聘", path: "/common/hire" },
      { name: "我的咨询", path: "/common/myadvice" },
    ],
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
    imga: "/doer-icon/nav-7-a.png",
    imgg: "/doer-icon/nav-7-g.png",
    icon: "guwen",
  },
  {
    name: "平台推送",
    path: "/common/push",
    imga: "/doer-icon/nav-7-a.png",
    imgg: "/doer-icon/nav-7-g.png",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
// 劳务
export const labor = [
  {
    name: "控制面板",
    path: "/labor/index",
    icon: "mianban",
  },
  {
    name: "企业信息",
    path: "/common/company",
    icon: "biangeng",
    children: [
      { name: "企业资料", path: "/common/company/doc" },
      { name: "我的账户", path: "/common/company/account" },
      { name: "账号管理", path: "/common/company/mag" },
    ],
  },
  {
    name: "人员供需管理",
    path: "/labor/personMag",
    children: [
      { name: "人员管理", path: "/labor/person2" },
      { name: "供应事项管理", path: "/labor/personSupply" },
      { name: "需求事项管理", path: "/common/myadvice2" },
      { name: "人才招聘", path: "/common/hire" },
    ],
  },
  {
    name: "项目管理",
    path: "/labor/projectMag",
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
  },
  {
    name: "服务项目列表",
    path: "/personMag/list2",
    icon: "biangeng",
  },
  {
    name: "我的咨询",
    path: "/common/myadviced",
    icon: "guwen",
    children: [
      { name: "我的咨询", path: "/common/myadvice" },

      { name: "咨询信息管理", path: "/platMag/consult/infoMag" },
    ],
  },
  {
    name: "平台推送",
    path: "/common/push",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
// 咨询公司
export const consult = [
  {
    name: "控制面板",
    path: "/consult/index",
    icon: "mianban",
  },
  {
    name: "企业信息",
    path: "/common/company",
    icon: "biangeng",
    children: [
      { name: "企业资料", path: "/common/company/doc" },
      { name: "我的账户", path: "/common/company/account" },
      { name: "账号管理", path: "/common/company/mag" },
    ],
  },
  {
    name: "服务项目管理",
    path: "/consult/list",
    icon: "xiangmu",
  },
  {
    name: "咨询服务",
    path: "/common/myadviced",
    icon: "xinxi",
    children: [
      { name: "咨询服务", path: "/common/service" },
      { name: "我的咨询", path: "/common/myadvice" },
    ],
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
    icon: "guwen",
  },
  {
    name: "平台推送",
    path: "/common/push",
    icon: "jindu",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
export const consultOther = [
  {
    name: "控制面板",
    path: "/consult/index2",
    icon: "mianban",
  },
  {
    name: "企业信息",
    path: "/common/company",
    icon: "biangeng",
    children: [
      { name: "企业资料", path: "/common/company/doc" },
      { name: "我的账户", path: "/common/company/account" },
      { name: "账号管理", path: "/common/company/mag" },
    ],
  },
  {
    name: "咨询服务",
    path: "/common/myadviced",
    children: [
      { name: "咨询服务", path: "/common/service" },
      { name: "我的咨询", path: "/common/myadvice" },
    ],
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
  },
  {
    name: "平台推送",
    path: "/common/push",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
export const serviceMagforConsult = [
  { name: "项目概况", path: "/consult/brife", icon: "gaikuang" },
  {
    name: "过控日志",
    path: "/consult/dictionary",
    icon: "rizhi",
  },
  {
    name: "待办事项",
    path: "/project/TwaitCase",
    icon: "shixiang",
    children: [
      { name: "待办事项列表", path: "/consult/waitCase" },
      { name: "计划事项列表", path: "/consult/waitCaseplan" },
    ],
  },
  {
    name: "工程资料",
    path: "/consult/doc",
    icon: "geren",
    // children: [
    //     { name: '竣工资料', path: '/consult/finishDoc' },
    //     { name: '工期资料', path: '/project/doc' }
    // ]
  },
  { name: "收方记录", path: "/consult/records", icon: "wenzhang" },
  { name: "签证", path: "/consult/visa", icon: "qianzheng" },
  { name: "变更", path: "/consult/change", icon: "biangeng" },
  { name: "清标", path: "/consult/clear", icon: "qingbiao" },
  { name: "结算", path: "/consult/end", icon: "jiesuan" },
  { name: "进度款", path: "/consult/process", icon: "jindu" },
];
export const personMag = [
  {
    name: "控制面板",
    path: "/personMag/index",
    icon: "mianban",
  },
  {
    name: "我的信息",
    path: "/personMag/me",
    icon: "biangeng",
    children: [
      { name: "我的资料", path: "/personMag/info" },
      { name: "我的业绩", path: "/common/company/achievment" },
      { name: "我的账户", path: "/common/company/account" },
    ],
  },
  {
    name: "服务项目列表",
    path: "/personMag/list2",
    icon: "biangeng",
  },
  {
    name: "我的发布",
    path: "/common/myadviced",
    icon: "guwen",
    children: [
      { name: "我的咨询", path: "/common/myadvice" },
      { name: "我的求职", path: "/common/myfind" },
      { name: "咨询信息管理", path: "/platMag/consult/infoMag" },
      { name: "我的工程", path: "/common/engineer" },
      { name: "我的代理", path: "/common/agency" },
    ],
  },
  {
    name: "我的顾问",
    path: "/common/adviser",
    icon: "zixun",
  },
  {
    name: "代理业务",
    path: "/personMag/agency",
    icon: "zixun",
  },
  {
    name: "平台推送",
    path: "/common/push",
    icon: "shixiang",
    children: [
      { name: "交易推送", path: "/common/platformPush" },
      { name: "其他推送", path: "/common/push" },
    ],
  },
];
export const platMag = [
  {
    name: "控制面板",
    path: "/platMag/index",
    icon: "mianban",
  },
  {
    name: "系统设置",
    path: "/platMag/info",
    icon: "xitong",
  },
  { name: "菜单", path: "/common/auth", icon: "chouhua" },
  {
    name: "账号管理",
    path: "/platMag/me",
    icon: "zhanghao",
    children: [
      { name: "组织架构管理", path: "/platMag/account/frameCommunity" },
      { name: "平台账号管理", path: "/platMag/account/plat" },
      { name: "到期账号提醒", path: "/platMag/account/expire" },
      {
        name: "用户账号管理",
        path: "/personMag/account",
        children: [
          { name: "项目账号", path: "/platMag/account/task?type=1" },
          { name: "施工企业账号", path: "/platMag/account/consTask?type=2" },
          { name: "材料供应商", path: "/platMag/account/task?type=3" },
          { name: "机械供应商", path: "/platMag/account/task?type=4" },
          { name: "劳务公司账号", path: "/platMag/account/task?type=5" },
          { name: "咨询公司账号", path: "/platMag/account/task?type=6" },
          { name: "其他咨询公司账号", path: "/platMag/account/task?type=7" },
          { name: "专家账号", path: "/platMag/account/expertTask?type=8" },
          { name: "班组长账号", path: "/platMag/account/indTask?type=9" },
        ],
      },
    ],
  },
  {
    name: "项目管理",
    path: "/platMag/list",
    icon: "gaikuang",
  },
  {
    name: "需求信息管理",
    path: "/platMag/need",
    icon: "biangeng",
    children: [
      { name: "人才招聘", path: "/common/hire" },
      { name: "工程劳务需求", path: "/platMag/labor" },
      { name: "采购发布", path: "/platMag/purchase" },
      { name: "企业业绩", path: "/common/company/achievment" },
    ],
  },
  {
    name: "供应信息管理",
    path: "/platMag/supply",
    icon: "gongyingshang",
    children: [
      { name: "求职信息管理", path: "/platMag/supply/hire" },
      { name: "工程劳务信息管理", path: "/platMag/supply/laborInfo" },
      { name: "供应材料管理", path: "/platMag/supply/material" },
      { name: "供应机械管理", path: "/platMag/supply/machine" },
      { name: "劳务人员管理", path: "/platMag/supply/labor" },
    ],
  },
  {
    name: "代理商管理管理",
    path: "/platMag/agency",
    icon: "gongyingshang",
  },
  {
    name: "企业管理",
    path: "/platMag/companyMag",
    icon: "qiye",
    children: [
      { name: "施工企业管理", path: "/platMag/companyMag/company" },
      {
        name: "材料供应商管理",
        path: "/platMag/companyMag/stuff?type=material",
      },
      {
        name: "机械供应商管理",
        path: "/platMag/companyMag/stuff?type=machine",
      },
      { name: "劳务公司管理", path: "/platMag/companyMag/labor" },
      { name: "咨询公司管理", path: "/platMag/companyMag/adviceMag" },
      { name: "其他咨询公司管理", path: "/platMag/companyMag/otherAdviceMag" },
    ],
  },
  {
    name: "咨询服务",
    path: "/platMag/myadviced",
    icon: "xinxi",
    children: [
      { name: "我的咨询", path: "/common/myadvice" },
      { name: "咨询信息管理", path: "/platMag/consult/infoMag" },
      { name: "咨询介入管理", path: "/platMag/consult/injection" },
      { name: "平台承接服务", path: "/platMag/consult/takingService" },
      { name: "平台客服", path: "/platMag/consult/service" },
    ],
  },
  {
    name: "平台推送",
    path: "/common/push",
    icon: "fuwu",
    children: [
      { name: "推送列表", path: "/common/platformPush" },
      { name: "模板管理", path: "/platMag/template" },
    ],
  },
  {
    name: "资讯管理",
    icon: "xinwen",
    path: "/platMag/news/Mag",
    children: [
      { name: "关于我们", path: "/platMag/news/deal?type=-0" },
      { name: "地图管理", path: "/platMag/news/map" },

      { name: "协议管理", path: "/platMag/news/agree" },
      {
        name: "广告管理",
        path: "/platMag/news/advertise",
        children: [
          { name: "平台广告管理", path: "/platMag/news/platform" },
          { name: "商业广告管理", path: "/platMag/news/advertise" },
        ],
      },
    ],
  },
  {
    name: "工程咨询与服务",
    icon: "guwen",
    path: "/platMag/small",
    children: [{ name: "文章", path: "/platMag/article" }],
  },
  {
    name: "工程咨询与服务",
    icon: "guwen",
    path: "/platMag/consultandservice",
    children: [
      { name: "操作手册", path: "/platMag/book" },
      { name: "线下培训", path: "/platMag/train" },
      { name: "反馈意见箱", path: "/platMag/feedback" },
    ],
  },
  {
    name: "关键词配置",
    path: "/platMag/keyword",
    icon: "gaikuang",
  },
];
