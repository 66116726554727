import { isMobile } from "./utils";
const reset = () => {
  let appDom = document.querySelector("#app");
  let cliWidth = document.documentElement.clientWidth || document.body.clientWidth;
  let contW = 1920;
  let w = cliWidth / contW;

  appDom.style.transform = `scale(${w})`;
  appDom.style.transformOrigin = "top left";
  appDom.style.width = `${contW}px`;
};

const flag = isMobile();
window.addEventListener("resize", reset);
if (!flag) window.removeEventListener("resize", reset);

setTimeout(() => {
  flag && reset();
});
