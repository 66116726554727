<template>
  <div>
    <div class="flex">
      <el-upload
        class="avatar-uploader"
        :class="{ hideUpload: hideUploadEdit }"
        :action="url + '/api/admin.upload/upload'"
        :show-file-list="false"
        :auto-upload="false"
        v-loading="loading"
        :multiple="true"
        :accept="accept"
        :on-change="
          (file, fileList) => {
            handleAvatarSuccess(file, fileList);
          }
        "
        :before-upload="beforeAvatarUpload"
      >
        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="imgs flex" v-if="imgs.length > 0">
        <div
          v-for="(item, index) in imgs"
          class="ml-20"
          :key="index"
          :class="{ act: item.type !== 'image' && item.type !== 'video' }"
          :style="{
            width: item.type == 'image' || item.type == 'video' ? '' : 'auto',
          }"
          style="border: 1px solid #e5e5e5; width: 83px; height: 83px; border-radius: 6px"
        >
          <!-- <div style="width: 83px;height: 83px; border: 1px solid #E5E5E5;display: flex;"> -->
          <!-- <span>{{ $url+item.url }}</span> -->
          <img :src="item.url" v-if="getUrl(item) == 'png' || getUrl(item) == 'jpg'" />
          <video autopay :src="item.url" v-else-if="item.type == 'video'"></video>
          <!-- <span v-else>{{ item.url }}</span> -->
          <!-- <span>{{ item.url.split(".")[1] }}</span> -->
          <img v-if="getUrl(item) == 'xlsx'" src="@/assets/file/xlsx.png" />
          <img v-else-if="getUrl(item) == 'txt'" src="@/assets/file/txt.png" />
          <img v-else-if="getUrl(item) == 'doc'" src="@/assets/file/doc.png" />
          <img v-else-if="getUrl(item) == 'docx'" src="@/assets/file/docx.png" />
          <!-- <img v-else-if="getUrl(item) == 'png'" src="@/assets/file/png.png" /> -->
          <img v-else-if="getUrl(item) == 'zip'" src="@/assets/file/zip.png" />
          <img v-else-if="getUrl(item) == 'xls'" src="@/assets/file/xls.png" />
          <img v-else-if="getUrl(item) == 'rar'" src="@/assets/file/rar.png" />
          <img v-else-if="getUrl(item).toLowerCase() == 'pdf'" src="@/assets/file/pdf.png" />
          <img v-else-if="getUrl(item) == 'dwg'" src="@/assets/file/dwg.png" />
          <!-- </div> -->
          <i class="el-icon-circle-close cur" @click="delImg(item, index)"></i>
        </div>
      </div>
    </div>
    <!-- <div v-if="files.length>0" class="over">
        <span>{{files[0].url}}</span>
        <i class="el-icon-circle-close cur" @click="delFile(files[0], 0)"></i>
      </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: '',
      hideUploadEdit: false,
      url: window.location.origin,
      imgs: [],
      files: [],
      loading: false,
      // imgs: []
    };
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: () => '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    close(val) {
      if (val) {
        this.imgs = [];
      }
    },
    list: {
      handler: function (val, oldVal) {
        // if (this.val.length > 0 && this.val[0]) {
        //   return
        // }
        if (this.imgs.length > 0 && this.imgs[0].url == '') this.imgs = [];
        if (val.length > 0 && this.imgs.length == 0) {
          this.imgs = [];
          val.forEach((item) => {
            let obj = {
              name: this.item.field,
              url: '',
            };
            if (!item) {
              return;
            }
            if (item.indexOf('png') > -1 || item.indexOf('jpg') > -1 || item.indexOf('jpeg') > -1 || item.indexOf('gif') > -1) {
              obj.type = 'image';
              obj.url = item;
              this.imgs.push(obj);
            } else if (item.indexOf('mp4') > -1) {
              obj.type = 'video';
              obj.url = item;
              this.imgs.push(obj);
            } else {
              obj.url = item;
              this.imgs.push(obj);
            }
          });
        }
        //  if (this.imgs[0].url) {
        //   console.log(this.imgs[0].url,'哈哈')
        //   let arr = this.imgs[0].url
        //   this.imgs = arr.map(url => {
        //     let obj = {
        //       name: this.imgs[0].name,
        //       url: url
        //     };
        //     return obj;
        //   });
        // }
        // this.$emit('fileurl',this.imgs)
      },
      immediate: true,
    },
    // list(val) {
    //   console.log(val);
    //   console.log(this.imgs);
    //   if (this.imgs.length > 0 && this.imgs[0].url == "") this.imgs = [];
    //   if (val.length > 0 && this.imgs.length == 0) {
    //     this.imgs = [];
    //     val.forEach((item) => {
    //       let obj = {
    //         name: this.item.field,
    //         url: "",
    //       };
    //       if (
    //         item.indexOf("png") > -1 ||
    //         item.indexOf("jpg") > -1 ||
    //         item.indexOf("jpeg") > -1 ||
    //         item.indexOf("gif") > -1
    //       ) {
    //         obj.type = "image";
    //         obj.url = this.$url + item;
    //         this.imgs.push(obj);
    //       } else if (item.indexOf("mp4") > -1) {
    //         obj.type = "video";
    //         obj.url = this.$url + item;
    //         this.imgs.push(obj);
    //       } else {
    //         obj.url = item;
    //         this.imgs.push(obj);
    //       }
    //     });
    //   } else {
    //     console.log('222')
    //     // this.imgs.push(val);
    //   }
    // },
    path(val) {
      this.files = [];

      if (val != '' && this.imgs.length == 0) {
        this.imgs = [];
        if (val.indexOf('png') > -1 || val.indexOf('jpg') > -1 || val.indexOf('jpeg') > -1 || val.indexOf('gif') > -1) {
          this.imgs = [{ name: this.item.field, url: val, type: 'image' }];
        } else if (val.indexOf('mp4') > -1) {
          this.imgs = [{ url: val, name: this.item.field, type: 'video' }];
        } else {
          this.imgs = [{ name: this.item.field, url: val }];
        }
      } else if (val == '') {
        this.imgs = [];
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.path != '' && this.imgs.length == 0) {
        if (this.path.indexOf('png') > -1 || this.path.indexOf('jpg') > -1 || this.path.indexOf('jpeg') > -1 || this.path.indexOf('gif') > -1) {
          this.imgs = [
            {
              name: this.item.field,
              url: this.path,
              type: 'image',
            },
          ];
        } else if (this.path.indexOf('mp4') > -1) {
          this.imgs = [
            {
              url: this.path,
              name: this.item.field,
              type: 'video',
            },
          ];
        } else {
          this.imgs = [{ name: this.item.field, url: this.path }];
        }
      }
    }, 1000);
  },
  methods: {
    progress(file) {},
    getUrl(item) {
      let List = item.url.split('.');
      let name = '';

      if (List.length > 0) {
        // let _url = `@/assets/file/${name}.png`;

        return List[List.length - 1];
      }
    },
    delImg(item, index) {
      item.index = index;
      this.$emit('fileDel', item);
      this.imgs.splice(index, 1);
    },
    delFile(item, index) {
      item.index = index;
      this.$emit('fileDel', item);
      this.files.splice(index, 1);
    },
    handleAvatarSuccess(file, filelist) {
      // this.imgs = []
      this.files = [];
      // console.log(file, filelist, '哈哈哈');

      let formData = new FormData();
      formData.append('file_name', file.raw);
      // formData.append("file_title", file.raw.name);
      // formData.append("file", file.raw);
      this.loading = true;
      this.$http({
        url: '/api/admin.upload/upload',
        data: formData,
        header: true,
        done: (res) => {
          // console.log(res, '服务器返回的文件地址');
          this.loading = false;
          if (file.raw.type.indexOf('image/png') > -1) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.imgs.push({ url: res, name: this.item.field, type: 'image' });
          } else if (file.raw.type.indexOf('video') > -1) {
            this.imgs.push({ url: res, name: this.item.field, type: 'video' });
          } else {
            this.imgs.push({ url: res, name: this.item.field });
          }
          // console.log(this.imgs, '上传成功的文件');
          // this.imgs.push({ res: res, name: this.item.field, type: this.item.type2, title: file.raw.name })
          this.$emit('fileurl', { res: res, name: this.item.field, type: this.item.type2, title: file.raw.name });
        },
      });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 50MB!');
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.act {
  // width: 82px;
  //   border: 1px solid #d9d9d9;
  //   border-radius: 8px;
  //   height: 82px;
}

.over {
  width: 350px;
  line-height: 13px;

  span {
    display: inline-block;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  i {
    margin-left: 10px;
  }
}

video {
  width: 88px;
  height: 84px;
}

.imgs div {
  position: relative;

  img {
    width: 86px;
    height: 86px;
  }

  i {
    position: absolute;
    right: -18px;
    color: #409eff;
    font-size: 18px;
  }
}
</style>
<style>
.avatar {
  width: 80px;
  height: 80px;
}

.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  color: #e5e5e5;
  width: 83px;
  height: 83px;
  line-height: 83px;
  font-size: 40px;
  text-align: center;
}

.hideUpload {
  /deep/ .avatar-uploader-icon {
    display: none !important;
  }
}
</style>