<template>
    <div class="sidebar">
        <div class="sidebar_mains">
            <div class="content">
                <div class="item" @click="go(1)">
                    <i class="el-icon-orange"></i>
                    <a>官网</a>
                </div>
                <div class="item"  @click="go(2)">
                    <i class="el-icon-notebook-1"></i>
                    <a>操作手册</a>
                </div>
                <!-- <div class="item"  @click="go(3)">
                    <i class="el-icon-chat-dot-round"></i>
                    <a>消息</a>
                </div> -->
                <div class="item"  @click="go(4)">
                    <i class="el-icon-top"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {

        };
    },
    computed: {},
    watch: {},
    methods: {
        go(type){
            switch(type){
                case 1: window.open('https://www.sckjxg.com/');
                break;
                case 2:this.$router.push({
                    path:'/front/newPage/info/operate'
                })
                break;
                case 3 : this.goMessage()
                break;
                case 4 : this.goTop()
                break;
            }
        },
        goMessage(){
            let token = sessionStorage.getItem('token')
            if(!token){
                this.$router.push({
                    path:'/login'
                })
            }
        },
        goTop(){
            this.$nextTick(() => {
               const app = document.getElementById('app');
               app.scrollIntoView({behavior: "smooth"});
            });
        }
    },
}
</script>
<style lang="less" scoped>
.sidebar{
    width: 64px;
    position: fixed;
    right: 12%;
    top: 30%;
    z-index: 3;
    .content{
        width:100%;
        .item{
            width: 100%;
            height: 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            margin: 8px 0;
            border-radius: 8px;
            font-size: 30px;
            color: rgba(153, 153, 153, 1);
            cursor: pointer;
            a{
                margin-top: 4px;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 15.84px;
                color: rgba(0, 0, 0, 1);
                text-align: left;
                vertical-align: top;

            }
        }
    }
}
</style>