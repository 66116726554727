<template>
  <div>
    <div class="flex">
      <el-upload class="avatar-uploader" :class="{hide:showUpload}" :action="url + '/api/admin.upload/upload'"
        :show-file-list="false" :auto-upload="false" v-loading="loading" :on-change="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="imgs flex">
        <div v-for="(item, index) in imgs" class="ml-20" :key="index"
          :class="{act: item.type !== 'image' && item.type !== 'video'}"
          :style="{width: item.type== 'image' || item.type== 'video'? '': 'auto'}">
          <img :src="item.url" v-if="item.type== 'image'" />
          <video autopay :src="item.url" v-else-if="item.type=='video'"> -1"></video>
          <span v-else>{{ item.url}}</span>
          <!-- <img v-else src="@/assets/att/xlsx.png" /> -->
          <i class="el-icon-circle-close cur" @click="delImg(item, index)"></i>
        </div>
      </div>
    </div>
    <!-- <div v-if="files.length>0" class="over">
      <span>{{files[0].url}}</span>
      <i class="el-icon-circle-close cur" @click="delFile(files[0], 0)"></i>
    </div> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        imageUrl: '',
        url: window.location.origin,
        imgs: [],
        files: [],
        loading: false,
        showUpload: true
      };
    },
    props: {
      close: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default: () => { }
      },
      path: {
        type: String,
        default: () => ''
      },
      limit: {
        type: Number,
        default: () => 10
      },
    },
    watch: {
      close(val) {
        if (val) {
          this.imgs = []
        }
      },
      path(val) {
        this.files = []
        if (val != '' && this.imgs.length == 0) {
          this.imgs = []
          if (val.indexOf('png') > -1 || val.indexOf('jpg') > -1 || val.indexOf('jpeg') > -1 || val.indexOf('gif') > -1) {
            this.imgs = [{ name: this.item.field, url: val, type: 'image' }]
          } else if (val.indexOf('mp4') > -1) {
            this.imgs = [{ url: val, name: this.item.field, type: 'video' }]
          } else {
            this.imgs = [{ name: this.item.field, url: val }]
          }
        } else if (val == '') {
          this.imgs = []
        }
      }
    },
    mounted() {
      setTimeout(() => {
        if (this.path != '' && this.imgs.length == 0) {
          if (this.path.indexOf('png') > -1 || this.path.indexOf('jpg') > -1 || this.path.indexOf('jpeg') > -1 || this.path.indexOf('gif') > -1) {
            this.imgs = [{ name: this.item.field, url: this.path, type: 'image' }]
          } else if (this.path.indexOf('mp4') > -1) {
            this.imgs = [{ url: this.path, name: this.item.field, type: 'video' }]
          } else {
            this.imgs = [{ name: this.item.field, url: this.path }]
          }
        }
      }, 1000);
    },
    methods: {

      delImg(item, index) {

        item.index = index
        this.$emit('fileDel', item)
        this.imgs.splice(index, 1)
      },
      delFile(item, index) {

        item.index = index
        this.$emit('fileDel', item)
        this.files.splice(index, 1)
      },
      handleAvatarSuccess(file) {

        // var FileExt = file.name.replace(/.+\./, "");
        // if (['png', 'jpg', 'jpeg'].indexOf(FileExt.toLowerCase()) === -1) {
        //   this.$message({
        //     type: "warning",
        //     message: "请上传.png .jpg .jpeg格式的文件"
        //   });
        //   return false;
        // }
        this.imgs = []
        this.files = []
        let formData = new FormData()
        formData.append("file_name", file.raw);
        // formData.append("file_title", file.raw.name);
        // formData.append("file", file.raw);
        this.loading = true
        this.$http({
          url: '/api/admin.upload/upload',
          data: formData,
          header: true,
          done: (res => {
            this.loading = false
            if (file.raw.type.indexOf('image') > -1) {
              this.imageUrl = URL.createObjectURL(file.raw);
              this.imgs.push({ url: URL.createObjectURL(file.raw), name: this.item.field, type: 'image' })
            } else if (file.raw.type.indexOf('video') > -1) {
              this.imgs.push({ url: URL.createObjectURL(file.raw), name: this.item.field, type: 'video' })
            } else {
              this.imgs.push({ url: file.raw.name, name: this.item.field })
            }
            this.$emit('fileurl', { res: res, name: this.item.field, type: this.item.type2, title: file.raw.name })
          })
        })
        if (this.imgs.length == this.limit) {
          this.showUpload = true
        }
        console.log("上传数据", this.imgs, this.imgs.length, this.limit, this.showUpload)
      },
      beforeAvatarUpload(file) {

        const isLt2M = file.size / 1024 / 1024 < 50;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 50MB!');
        }
        return isLt2M;
      },
      beforeUpload(file) {

        const isJPG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)
        const isLtSize = file.size / 1024 / 1024 < 2;
        if (['video/mp4'].indexOf(file.type) === -1) {
          this.$message.error('请上传正确的视频格式')
          return false
        }

        if (!isJPG) {
          this.$message.error('文件非 JPG/PNG/JPEG/GIF 格式')
          return false
        }
        if (!isLtSize) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return true
      },

    }
  }
</script>

<style lang="less" scoped>
  .act {
    // width: 82px;
    //   border: 1px solid #d9d9d9;
    //   border-radius: 8px;
    //   height: 82px;
  }

  .over {
    width: 350px;
    line-height: 13px;

    span {
      display: inline-block;
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      margin-left: 10px;
    }
  }

  video {
    width: 88px;
    height: 84px;
  }

  .imgs div {
    position: relative;

    img {
      width: 86px;
      height: 86px;
    }

    i {
      position: absolute;
      right: -18px;
      color: #409EFF;
      font-size: 18px;
    }

  }

  .hide .el-upload {
    display: none !important;
  }
</style>
<style>
  .avatar {
    width: 80px;
    height: 80px;
  }

  .avatar-uploader .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #E5E5E5;
    width: 83px;
    height: 83px;
    line-height: 83px;
    font-size: 40px;
    text-align: center;
  }
</style>