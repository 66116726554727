<template>
    <div class="footer">
        <div class="mains">
            <div class="contentTop">
                <div class="left">
                    <div class="phone">
                        <div><img src="@/assets/telephone.png" alt="">服务热线:</div>
                        <div>18708425606</div>
                    </div>
                    <div class="address">
                        <div><img src="@/assets/address.png" alt="">公司地址</div>
                        <div>{{ contact.address }}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="content">
                        <div class="item" v-for="(item,index) in menu" :key="item.id">
                            <div class="Menu" @click="go(item,index)">{{ item.name }}</div>
                            <div class="cjildMenu" v-for="el in item.children" :key="el.id">
                                <div>{{ el.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contentBotm">
                <div class="content">
                    <div class="left">
                        <div>Copyright @ 2000-2023 四川科建项管科技有限公司 ALL RIGHTS RESERVED</div>
                        <div>
                            <a>{{ contact.postal_code }}</a>
                            <a>{{ contact.email }}</a>
                        </div>
                        <div>
                            <a>建议您使用Chrome、360或Edge等主流浏览器浏览本网站</a>
                            <a>站长统计</a>
                        </div>
                    </div>
                    <div class="right">
                        <div><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022029672号-1</a></div>
                        <!-- <div>{{ contact.filing_address }}</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            menu:[
                {
                    id:1,
                    name:'工程招聘',
                    path:'/front/hire?type=recruit',
                    children:[
                        {
                            id:2,
                            name:'招聘信息',
                            path:''
                        },
                        {
                            id:3,
                            name:'求职信息',
                        },
                        {
                            id:4,
                            name:'工程劳务需求',
                        },
                        {
                            id:5,
                            name:'工程劳务供应',
                        }
                    ]
                },
                {
                    id:6,
                    name:'工程材料',
                    path:'/front/material?type=material',
                    children:[
                        {
                            id:7,
                            name:'材料供应信息',
                        },
                        {
                            id:8,
                            name:'材料采购信息',
                        }
                    ]
                },
                {
                    id:12,
                    name:'工程机械',
                    path:'/front/machine?type=machinery',
                    children:[
                        {
                            id:13,
                            name:'机械供应信息',
                        },
                        {
                            id:16,
                            name:'机械采购信息',
                        },
                    ]
                },
                {
                    id:17,
                    name:'综合服务大厅',
                    path:'/front/business?type=comprehensive',
                    children:[
                        {
                            id:18,
                            name:'咨询服务专家',
                        },
                        {
                            id:19,
                            name:'咨询服务公司',
                        },
                        {
                            id:20,
                            name:'咨询业务需求',
                        }
                    ]
                },
                {
                    id:22,
                    name:'关于我们',
                    path:'/front/about',
                    children:[
                        {
                            id:23,
                            name:'平台介绍',
                        },
                        {
                            id:24,
                            name:'线下培训',
                        },
                        {
                            id:25,
                            name:'操作手册',
                        },
                        {
                            id:26,
                            name:'意见反馈',
                        },
                        {
                            id:27,
                            name:'免责申明',
                        }
                    ]
                }
            ],
            contact:''
        };
    },
    computed: {},
    watch: {},
    methods: {
        getInfo(){
            this.$http({
                url:'/api/index.index/getIndexInfo',
                done:res=>{
                    console.log(res,'底部详情');
                    this.contact = res.contact
                }
            })
        },
        go(url,index){
            console.log('kajsdklajd');
            this.$bus.$emit('gos',url,index+1)
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
.footer{
    width: 100%;
    // height: 500px;
    padding: 0;
    margin: 0;
    background: rgb(51, 51, 51);
    a{
        text-decoration: none;
        color: #999;
    }
    .mains{
        width: 100%;
        padding: 20px 10%;
        height: 100%;
        box-sizing: border-box;

        .contentTop{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;

            .left{
                width: 45%;
                .phone{
                    color: #fff;
                    img{
                        width: 18px;
                        margin-right: 5px;
                    }
                    div:nth-child(1){
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: rgb(165, 165, 165);
                        i{
                            font-size: 20px;
                            margin-right: 10px;
                            background: #fff;
                            border-radius: 50%;
                            padding: 2px;
                        }
                    }
                    div:nth-child(2){
                        font-family: SourceHanSansSC;
                        font-weight: 400;
                        font-size: 20px;
                        color: rgb(248, 248, 248);
                        font-style: normal;
                        letter-spacing: 0px;
                        line-height: 44px;
                        text-decoration: none;
                        margin-left: 30px;
                    }
                    div:nth-child(3){
                        margin-left: 30px;
                    }
                }

                .address{
                    color: #fff;
                    margin-top: 40px;
                    img{
                        width: 18px;
                        margin-right: 5px;
                    }
                    div:nth-child(1){
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: rgb(165, 165, 165);
                        i{
                            font-size: 20px;
                            margin-right: 10px;
                            background: #fff;
                            border-radius: 50%;
                            padding: 2px;
                        }
                    }
                    div:nth-child(2){
                        font-size: 14px;
                        margin-left: 30px;
                        padding: 0 100px 0 0 ;
                        box-sizing: border-box;
                        margin-top: 8px;
                        width: 60%;
                    }
                }
            }
            .right{
                width: 50%;
                .content{
                    width: 100%;
                    color: #fff;
                    display: flex;
                    font-size: 12px;
                    .item{
                        width: 100%;
                        .Menu{
                            color: #fff;
                            font-weight: 600;
                            // font-size: 12px;
                            margin-bottom: 40px;
                            font-weight: 700;
                            cursor: pointer;
                        }
                        .cjildMenu{
                            color: #ffffff;
                            margin-top: 20px;
                            cursor: pointer;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .contentBotm{
            width: 100%;
            margin-top: 15px;
            border-top: 2px solid #fff;
            .content{
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .left{
                    color: #999999;
                    font-size: 14px;
                    div{
                        margin-top: 10px;
                        a:nth-child(2){
                            margin-left: 20px;
                        }
                    }
                }
                .right{
                    font-size: 14px;
                    color: #999999;
                    text-align: right;
                    div{
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

</style>