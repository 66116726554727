<template>
    <div class="footer" :class="{ 'otherfoot': $route.name == 'login' }">
        <div class="container">
            <div class="top">
                <div>
                    <div class="icon"><i class="el-icon-phone" /></div>
                    <div class="content">
                        <div>服务热线</div>
                        <div>400-888-8888</div>
                        <div>1885558888888</div>
                    </div>
                </div>
                <div>
                    <div class="icon"><i class="el-icon-location" /></div>
                    <div class="content">
                        <div>公司地址</div>
                        <div>{{ info.address }}</div>
                    </div>
                </div>
                <div>
                    <div class="link" v-for="(item, index) in list" :key="item.label">{{ item.label }}&nbsp;{{ index < list.length
                        - 1 ? '/' : '' }}&nbsp;</div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-left">
                        <div>© {{ year || '2023' }} {{ info.company_name }}</div>
                        <div>邮编:{{ info.postal_code }} &nbsp;&nbsp;&nbsp; {{ info.email }}</div>
                        <div>建议您使用Chrome、Edge和360等主流浏览器浏览本网站</div>
                    </div>
                    <div class="bottom-right">
                        <el-image :src="src" fit="cover"></el-image>&nbsp;{{ info.filing_no }}
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    data() {
        return {
            src: require("@/assets/icon-beian.png"),
            info: {},
            year: "2023",
            list: [
                { label: '关于我们', src: '' },
                { label: '综合服务大厅', src: '' },
                { label: '工程招聘', src: '' },
                { label: '工程材料', src: '' },
                { label: '工程机械', src: '' },
                { label: '帮助中心', src: '' },
                { label: '免责声明', src: '' },
            ]
        }
    },
    created() {
        this.$http({
            url: "api/index.index/getIndexInfo",
            data: {},
            add: true,
            done: (res) => {
                this.info = res.contact;
            },
        });
    },
    mounted() {
        this.getYear()
    },
    methods: {
        tomit() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        },
        getYear() {
            this.year = new Date().getFullYear();
        }
    }
}

</script>
<style scoped lang='less'>
.footer {
    background: #313438;
    color: #8d92a1;
    padding: 50px 0 20px;
    margin-top: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    .container {
        width: 1280px;

        .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font-size: 12px;

            &>div {
                display: flex;

                &:nth-child(2) {
                    width: 300px;
                }

                .icon {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }

                .content {
                    div:nth-child(1) {
                        // font-size: 12px;
                        color: rgba(255, 255, 255, .8);
                    }
                }
            }
            .link {
                font-size: 14px;
            }
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            color: #8d92a1;
            font-size: 12px;

            .bottom-left {}

            /deep/.bottom-right {
                .el-image {
                    width: 14px;
                    vertical-align: middle;
                }
            }
        }
    }
}
</style>