const userList = {
    code: 200,
    message: 'success',
    data: {
        total: 0,
        'rows|10': [{
            id: '@guid',
            "project_id": 24,
            "visa_date": null,
            // "visa_money": "1000.00",
            'visa_money|100-3000': 130,
            "visa_info": "@cname",
            "cost_total": "10.00",
            "cost_diff_total": "10.00",
            "file_src": null,
            "delete_time": null,
            "create_time": "2022-11-07 11:52:09",
            "update_time": "2022-11-07 11:52:09",
            name: '@cname',
            'age|20-30': 23,
            'job|1': ['前端工程师', '后端工程师', 'UI工程师', '需求工程师']
        }]
    }
};
const pleadList = {
    code: 200,
    message: 'success',
    data: {
        total: 0,
        static: {
            "visa_total": 2,
            "visa_money": "1,555.00",
            "cost_total": "0.00",
            "cost_diff_total": "0.00"
        },
        'list|10': [{
            id: '@guid',
            "project_id": 24,
            "visa_date": '2022-11-07 11:52:09',
            // "visa_money": "1000.00",
            'visa_money|100-3000': 130,
            "visa_info": "@cname",
            "cost_total": "10.00",
            "cost_diff_total": "10.00",
            "file_src": null,
            "delete_time": null,
            "create_time": "2022-11-07 11:52:09",
            "update_time": "2022-11-07 11:52:09",
            name: '@cname',
            'age|20-30': 23,
            'job|1': ['前端工程师', '后端工程师', 'UI工程师', '需求工程师']
        }]
    }
};
export default {
    'get|/visa_list': userList,
    'post|/pleadList': pleadList
}

