<template>
  <div class="UE-ditor-plus">
    <vue-ueditor-wrap
      ref="ue"
      v-model="content"
      :editor-id="`editor-${id}`"
      :config="editorConfig"
      :editorDependencies="['ueditor.config.js', 'ueditor.all.js', 'lang/zh-cn/zh-cn.js']"
      @ready="ready"
    />
    <div class="option" v-if="showDelete">
      <i class="el-icon-delete" @click.stop="handleDelete"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    // 工具栏配置
    toolbars: {
      type: Array,
      default: () => [['fontfamily', 'fontsize', 'bold', '|', 'forecolor', 'backcolor', '|', 'justifyleft', 'justifycenter', 'justifyright']],
    },
    // 是否显示删除按钮
    showDelete: {
      type: Boolean,
      default: false,
    },
    // 初始化内容
    initialContent: {
      type: String,
      default: '',
    },
    // 初始化编辑器高度 默认320
    initialFrameHeight: {
      type: Number,
      default: 20,
    },
    // 自动高度，开启自动高度，默认为 true
    autoHeightEnabled: {
      type: Boolean,
      default: true,
    },
    isPaste: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      content: '',
      editorConfig: {
        // 编辑器后端服务接口，参考后端规范 https://open-doc.modstart.com/ueditor-plus/backend.html
        serverUrl: '',
        // 配置 UEditorPlus 的静态资源根路径，可以是 CDN 的静态资源地址
        UEDITOR_HOME_URL: '/UEditorPlus/',
        UEDITOR_CORS_URL: '/UEditorPlus/',
        toolbars: [], // 工具栏配置
        enableContextMenu: false, // 打开右键菜单功能，默认为 true
        shortcutMenu: [], // 快捷菜单
        autoHeightEnabled: true, // 自动高度，开启自动高度，默认为 true
        elementPathEnabled: false, // 是否启用元素路径，默认是显示
        // focus: true, // 初始化时，是否让编辑器获得焦点，默认为 false
        initialFrameHeight: 20, // 初始化编辑器高度 默认320
        initialFrameWidth: 'auto', // 初始化编辑器宽度
        wordCount: false, // 是否开启字数统计
        initialStyle: 'p{line-height:1em; margin: 5px 0 !important;}',
        initialContent: '', // 初始化内容
        allowDivTransToP: false,
      },
    };
  },
  created() {
    for (const key in this.editorConfig) {
      if (Object.hasOwnProperty.call(this.editorConfig, key)) {
        if (this[key] || this[key] === true || this[key] === false) this.editorConfig[key] = this[key];
      }
    }
  },
  methods: {
    handleDelete() {
      this.$emit('onDelete');
    },
    ready(data) {
      this.editor = data;
      this.editor.addListener('contentChange', () => {
        this.$emit('onContentchange', data.getContent());
      });
      this.editor.addListener('beforepaste', (name, html) => {
        if (!this.isPaste) {
          html.html = '';
          this.$message.warning('禁止粘贴');
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.UE-ditor-plus {
  position: relative;
  /deep/.edui-editor {
    .edui-editor-iframeholder {
      padding-bottom: 20px;
    }
  }
  .option {
    height: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding-left: 10px;
    i {
      cursor: pointer;
      font-size: 13px;
      color: hsl(0, 11%, 75%);
      &:hover {
        color: #3d7fff;
      }
    }
  }
}
</style>
