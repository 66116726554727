let apiURL = ''
const proURL = 'epms.kejiankeji.com' // 在线地址
const proURL2 = 'www.kejiankeji.com'  //在线地址
const kaifaURL = 'kaifaweb.kejiankeji.com' // 开发地址
const proAPI = 'https://api.kejiankeji.com/' // 在线APIW
const devURL = 'kj.kejiankeji.com' // 测试地址
const devAPI = 'https://kjapi.kejiankeji.com/' // 测试API
const kaifaAPI = 'https://kaifa.kejiankeji.com/' // 开发API

const hostname = location.hostname
if (hostname == proURL || hostname == proURL2) {
    apiURL = proAPI
} else if (hostname == devURL) {
    apiURL = devAPI
} else if(hostname == kaifaURL){
    apiURL = kaifaAPI
}else{
    apiURL =  devAPI
}

export {
    apiURL
}
