<template>
	<div>
		<!-- <el-upload class="avatar-uploader" :headers='headers' :action="url+uploadUrl" name="img" :show-file-list="false"
			:on-success="uploadSuccess" :before-upload="beforeUpload">
		</el-upload> -->
		<el-upload class="avatar-uploader" :action="url + '/api/admin.upload/upload'" :show-file-list="false"
			:auto-upload="false" v-loading="loading" :on-change="handleAvatarSuccess">

		</el-upload>

		<quill-editor ref="myQuillEditor" v-model="content2" :options="editorOption" @blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)" @ready="onEditorReady($event)" @change="onEditorChange($event)" />

	</div>
</template>
<script>
	// 工具栏配置
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
		['blockquote', 'code-block'], //引用，代码块
		[{
			'header': 1
		}, {
			'header': 2
		}], // 几级标题
		[{
			'list': 'ordered'
		}, {
			'list': 'bullet'
		}], // 有序列表，无序列表
		[{
			'script': 'sub'
		}, {
			'script': 'super'
		}], // 下角标，上角标
		[{
			'indent': '-1'
		}, {
			'indent': '+1'
		}], // 缩进
		[{
			'direction': 'rtl'
		}], // 文字输入方向
		[{
			'size': ['small', false, 'large', 'huge']
		}], // 字体大小
		[{
			'header': [1, 2, 3, 4, 5, 6, false]
		}], // 标题
		[{
			'color': []
		}, {
			'background': []
		}], // 颜色选择
		[{ font: ['Microsoft-YaHei', 'SimHei', 'SimSun', 'KaiTi', 'FangSong', 'Arial', 'sans-serif'] }],
		[{
			'align': []
		}], // 居中
		['clean'], // 清除样式,
		['link', 'image'],

	];

	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor
	} from 'vue-quill-editor'
	export default {
		props: {

			content: {
				type: String,
				default: ''
			}
		},
		name: "quillMyeditor",
		data() {
			return {
				// content: '',
				loading: false,
				content2: '',
				editorOption: {
					placeholder: '请在这里输入',
					theme: 'snow', //主题 snow/bubble

					modules: {
						history: {
							delay: 1000,
							maxStack: 50,
							userOnly: false
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function (value) {
									if (value) {
										// 调用element的图片上传组件
										document.querySelector('.avatar-uploader input').click()
									} else {
										this.quill.format('image', false)
									}
								}
							}
						}
					}
				},
				url: window.location.origin,
				uploadUrl: `/api/admin.upload/upload`, // 服务器上传地址
				headers: {
					"Content-Type": "application/json-patch+json",
					token: sessionStorage.getItem("token"),
				},

			}
		},
		components: {
			quillEditor
		},
		mounted() {

			this.content2 = this.content

		},
		methods: {

			uploadSuccess(res) {
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.quill
				// 如果上传成功
				if (res) {
					// 获取光标所在位置
					let length = quill.getSelection().index;
					// 插入图片，res为服务器返回的图片链接地址
					quill.insertEmbed(length, 'image', this.$url + res.data)
					// 调整光标到最后
					quill.setSelection(length + 1)
				} else {
					// 提示信息，需引入Message
					this.$message.error('图片插入失败！')
				}
			},
			handleAvatarSuccess(file) {

				//this.imgs.push({url: file.name, name: this.item.field})

				let formData = new FormData();
				formData.append("file_name", file.raw);
				// formData.append("file", file.raw);
				this.loading = true;
				this.$http({
					url: "/api/admin.upload/upload",
					data: formData,
					header: true,
					done: (res) => {
						this.loading = false;

						let quill = this.$refs.myQuillEditor.quill
						// 如果上传成功
						if (res) {
							// 获取光标所在位置
							let length = quill.getSelection().index;
							// 插入图片，res为服务器返回的图片链接地址
							quill.insertEmbed(length, 'image', this.$url + res)
							// 调整光标到最后
							quill.setSelection(length + 1)
						} else {
							// 提示信息，需引入Message
							this.$message.error('图片插入失败！')
						}

					},
				});
			},
			beforeUpload() {

			},
			add() {
				let obj = {}
				if (this.$route.query.type == -1) {
					obj.digitization = this.content
				} else if (this.$route.query.type == 0) {
					obj.about_us = this.content
				} else if (this.$route.query.type == 1) {
					obj.project_treaty = this.content
				} else if (this.$route.query.type == 2) {
					obj.prise_treaty = this.content
				} else if (this.$route.query.type == 3) {
					obj.user_treaty = this.content
				} else if (this.$route.query.type == 4) {
					obj.trans_treaty = this.content
				}
				this.$http({
					url: 'api/admin.supply.esta/config_set',
					data: {
						about_us: this.content
					},
					done: (res => {

						this.$message.success('操作成功')
					})
				})
			},
			onEditorBlur(quill) {
				console.log('editor blur!', quill)
			},
			onEditorFocus(quill) {
				console.log('editor focus!', quill)
			},
			onEditorReady(quill) {
				console.log('editor ready!', quill)
			},
			onEditorChange({
				quill,
				html,
				text
			}) {
				// console.log('editor change!', html)
				this.content2 = html
				this.$emit('getcotent', html)
			}
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill
			}
		},
		watch: {
			content(val) {
				this.content2 = val;
			},
		}
	}
</script>
<style lang="less">
	.editor {
		line-height: normal !important;
		height: 500px;
	}

	.ql-snow .ql-tooltip[data-mode=link]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: '保存';
		padding-right: 0px;
	}

	.ql-snow .ql-tooltip[data-mode=video]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: '14px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
		content: '10px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
		content: '18px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
		content: '32px';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: '文本';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: '标题1';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: '标题2';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: '标题3';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: '标题4';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: '标题5';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: '标题6';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: '标准字体';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
		content: '衬线字体';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
		content: '等宽字体';
	}
</style>