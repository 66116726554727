<template>
  <div>
    <el-dropdown @command="getCom">
      <span class="el-dropdown-link">
        <el-input :disabled="disabled" v-model="val" clearable @input="selectVal" :placeholder="placeholder || '请输入'">
        </el-input>
      </span>
      <el-dropdown-menu slot="dropdown" style="width:400px" v-if="!disabled">
        <el-scrollbar style="height: 200px">
          <el-dropdown-item :command="item.value" v-for="(item, index) in temp" :key="index">{{ item.label }}
          </el-dropdown-item>
        </el-scrollbar>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  export default {
    props: {
      api: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
      requestData: {
        type: Object,
        default: () => { },
      },
      info: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => { },
      },
      options: {
        type: Array,
        default: () => { },
      },
      close: {
        type: Boolean,
        default: false,
      },
      defaultvalue: {
        type: String,
        default: "",
      },
      disabled2: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: "",
      }
    },
    data() {
      return {
        disabled: false,
        back: [],
        val: "",
        temp: [],
      };
    },
    watch: {
      disabled2(val) {
        this.disabled = val
      },
      defaultvalue(val) {

        this.val = val;
      },
      close(val) {

        if (val) {
          this.val = "";
        }
      },
      info(val) {
        console.log(val);
      },
      options(val) {

        this.temp = val;
        this.back = JSON.parse(JSON.stringify(val));
      },
    },
    mounted() {
      // this.$http({
      //     url: this.api,
      //     data: this.requestData,
      //     done: (res => {
      //         this.options = res
      //         this.back = res
      //     })
      // })
      this.val = this.defaultvalue;
      this.temp = this.options;
      this.back = JSON.parse(JSON.stringify(this.options));
      this.disabled = this.disabled2;
    },
    methods: {
      getCom(e) {

        this.val = this.back.filter((item) => item.value == e)[0].label;
        this.$emit("data", { label: this.val, id: e, name: this.item.field, names: this.info });
      },
      selectVal() {
        this.temp = [];
        let arr = JSON.parse(JSON.stringify(this.back));
        arr.forEach((item) => {
          if (item.label.indexOf(this.val) > -1) {
            this.temp.push(item);
          }
        });

        this.$emit("data", { label: this.val, id: "0", name: this.item.field, names: this.info });

      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .el-dropdown {
    width: 100%;

    .el-dropdown-menu{
      width: 22%;
    }
  }
</style>